import React, { useState, useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import { getClientTag } from "../utils/checkurl";

import {
  KBZSC_IN_WEB,
  VIBER_IN_WEB,
  CITY_REWARD_IN_WEB,
} from "../config/inwebclients";
import { useTheme } from "@mui/material/styles";

const CovidAlertBanner = ({ isInWeb, pageContent, pageName }) => {
  const [clientTag] = useState(getClientTag(window.location.search));
  const [bannerClassName, setBannerClassName] = useState("covidInfo");
  const theme = useTheme();

  useEffect(() => {
    if (clientTag === KBZSC_IN_WEB) {
      return setBannerClassName("bgkbz covidInfo");
    }
    if (clientTag === CITY_REWARD_IN_WEB) {
      return setBannerClassName("bgCityReward covidInfo");
    }

    if (clientTag === VIBER_IN_WEB) {
      return setBannerClassName("bgviber covidInfo");
    }
  }, [clientTag]);

  return (
    <>
      <Grid container className="bgWhiteBanner">
        {((!isInWeb && pageName && pageName === "home") ||
          (isInWeb &&
            clientTag !== VIBER_IN_WEB &&
            pageName &&
            pageName === "home")) && (
          <Grid
            item
            sm={12}
            xs={12}
            className="covidInfo"
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <a
              href="https://flymya.notion.site/Flymya-Travel-Information-f678cb0ee3204fc99e49b2e70106d14c"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Container
                className="covidTravelInfo"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="reliefTitle covidInfoTitle">
                  You can reach our customer service team every day from 7am
                  until 11pm. To find the latest information about travel
                  (restrictions){" "}
                  <span
                    className="highlight"
                    style={{ color: theme.palette.info.main }}
                  >
                    click here
                  </span>{" "}
                  or visit{" "}
                  <span
                    className="highlight"
                    style={{ color: theme.palette.info.main }}
                  >
                    flymya.co/faq
                  </span>
                </span>
              </Container>
            </a>
          </Grid>
        )}
        {isInWeb && clientTag === VIBER_IN_WEB && (
          <Grid item sm={12} xs={12} className={`bgviber covidInfo`}>
            <a
              href="viber://pa?chatURI=experiencemyanmarbot"
              rel="noopener noreferrer"
            >
              <Container
                className="covidTravelInfo"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="reliefTitle covidInfoTitle">
                  ←{" "}
                  {pageContent && pageContent.go_back_viber_chat
                    ? pageContent.go_back_viber_chat
                    : "Go back to Experience Myanmar in Viber"}
                </span>
              </Container>
            </a>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CovidAlertBanner;
