// This file is used for registering and unregistering the service worker

export function register(config) {
  if ("serviceWorker" in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    window.addEventListener("load", () => {
      const swOptions = { scope: "/" };
      navigator.serviceWorker
        .register(swUrl, swOptions)
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope,
          );
          if (config && config.onUpdate) {
            registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              if (installingWorker) {
                installingWorker.onstatechange = () => {
                  if (
                    installingWorker.state === "installed" &&
                    navigator.serviceWorker.controller
                  ) {
                    config.onUpdate(registration);
                  }
                };
              }
            };
          }
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
