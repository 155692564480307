import { Button, Dialog } from "@material-ui/core";

import React from "react";
import "../../assets/scss/loginusers/Profile.scss";

const EditProfileDialog = ({
  open,
  setOpen,
  editProfile,
  profileData,
  setIsSpinner,
}) => {
  const handleBackToEditProfile = () => {
    setIsSpinner(false);
    setOpen(false);
  };

  const handleEditProfile = (val) => {
    editProfile(profileData);
  };

  return (
    <Dialog maxWidth={50} id="deleteAccountDialog" open={open}>
      <div className="dialog">
        <div className="account">
          <span>Are you sure you want to update your profile?</span>
        </div>
        <div className="textWrapper">
          <span className="text">
            After you click "Save", you have to wait 7 days for next attempt.
          </span>
          <br />
        </div>
        <div style={{ marginTop: "25px" }} className="dialogBtnWrapper">
          <div style={{ width: "200px" }}>
            <Button
              onClick={handleEditProfile}
              style={{
                background: "#00aeef",
                color: "#fff",
                width: "100%",
                margintop: "10px",
              }}
            >
              Save
            </Button>
          </div>
          <div style={{ width: "200px" }}>
            <Button
              onClick={handleBackToEditProfile}
              variant="outlined"
              className="toProfile"
            >
              Continue Edit
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditProfileDialog;
