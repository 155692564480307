import React from "react";
import { Button, Grid, Modal } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { IMG_CDN_URL } from "../../config/constants";
const closeIcon = `${IMG_CDN_URL}/modal-close.svg`;

const CreatePasswordSuccessModal = ({ showModal, setShowModal }) => {
  return (
    <React.Fragment>
      <Modal
        open={showModal}
        id="reliefModal"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            // Set 'open' to false, however you would do that with your particular code.
            setShowModal(false);
          }
        }}
      >
        <Grid
          className="confirmationBox covidAlertBox"
          style={{
            width: "420px",
            backgroundColor: "white",
            outline: "none",
          }}
        >
          <Grid
            item={true}
            sm={12}
            style={{
              padding: "0 21px",
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={closeIcon}
              alt="close"
              onClick={() => setShowModal(false)}
              style={{
                width: "15px",
                height: "15px",
                position: "relative",
                top: "-30px",
                right: "0",
                zIndex: "1",
              }}
              className="closeImg"
            />
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutline style={{ fontSize: 68, color: green[500] }} />
            <div style={{ fontSize: "27px", marginTop: "35px" }}>
              Password Created!
            </div>
            <div style={{ marginTop: "13px", marginBottom: "20px" }}>
              Your password has been created successfully.
            </div>
          </div>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default CreatePasswordSuccessModal;
