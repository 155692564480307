import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import "./App.css";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getClientTag, checkInApp, checkInWeb } from "./utils/checkurl";
// import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { retry } from "./utils/retryloadable";
import "./assets/scss/Home.scss";
import "./assets/scss/HomeMobile.scss";
import ForgetPassword from "./pages/forgetpassword/ForgetPassword";
import CreatePassword from "./pages/CreatePassword";
import { Snackbar, Slide } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { isAuthenticated } from "./lib/auth";
import { clientV3 } from "./utils/v3utils";
import { removeFromLocalStorage } from "./utils/logoututil";
import { LOGIN_PEOPLE_DETAIL, IS_CUSTOMER } from "./config/constants";
import { GET_THEME_BY_CLIENT } from "./queries/promotionquery";
import { theme } from "./Theme";
import AddressBook from "./pages/loginusers/AddressBook";
import AddressBookDetail from "./pages/loginusers/AddressBookDetail";
import Theme from "./Theme";
import { GorgiasProvider } from "./GorgiasContext";

const MainPage = lazy(() => retry(() => import("./pages/MainPage")));
const FlightResult = lazy(() =>
  retry(() => import("./pages/flight/FlightResult")),
);
const CustomerInfo = lazy(() =>
  retry(() => import("./pages/flight/CustomerInfo")),
);
const PaymentPage = lazy(() =>
  retry(() => import("./pages/flight/PaymentPage")),
);
const ConfirmationPage = lazy(() =>
  retry(() => import("./pages/confirmation/ConfirmationPage")),
);

const HotelSearchResult = lazy(() =>
  retry(() => import("./pages/hotels/HotelSearchResult")),
);
const HotelDetailPage = lazy(() =>
  retry(() => import("./pages/hotels/HotelDetailPage")),
);

const HotelCustomerPage = lazy(() =>
  retry(() => import("./pages/hotels/HotelCustomerPage")),
);

const HotelPaymentPage = lazy(() =>
  retry(() => import("./pages/hotels/HotelPaymentPage")),
);
const ShowResultMessage = lazy(() =>
  retry(() => import("./pages/flight/ShowResultMessage")),
);

const BusResult = lazy(() => retry(() => import("./pages/buses/BusResult")));
const BusSeatList = lazy(() =>
  retry(() => import("./pages/buses/BusSeatList")),
);
const BusCustomerInfo = lazy(() =>
  retry(() => import("./pages/buses/BusCustomerInfo")),
);
const BusTransactionFail = lazy(() =>
  retry(() => import("./pages/buses/BusTransactionFail")),
);
const CarHome = lazy(() => retry(() => import("./pages/carrental/CarHome")));

const BalloonHome = lazy(() =>
  retry(() => import("./pages/balloon/BalloonHome")),
);

const TravelServicesHome = lazy(() =>
  retry(() => import("./pages/travelservices/index")),
);

const PromotionsPage = lazy(() => retry(() => import("./pages/Promotions")));
const PromotionDetailPage = lazy(() =>
  retry(() => import("./pages/PromotionDetail")),
);
const Page404 = lazy(() => retry(() => import("./pages/Page404")));
const B2BBalancePage = lazy(() =>
  retry(() => import("./pages/loginusers/B2BBalancePage")),
);
const B2BTopup = lazy(() => retry(() => import("./pages/loginusers/B2BTopup")));
const B2BTransfer = lazy(() =>
  retry(() => import("./pages/loginusers/B2BTransfer")),
);
const BookingHistory = lazy(() =>
  retry(() => import("./pages/loginusers/BookingHistory")),
);
const Profile = lazy(() => retry(() => import("./pages/loginusers/Profile")));
const ContactUs = lazy(() => retry(() => import("./pages/ContactUs")));
const AboutUs = lazy(() => retry(() => import("./pages/AboutUs")));
const PrivacyPolicy = lazy(() => retry(() => import("./pages/PrivacyPolicy")));
const PrivacyPolicyBoca = lazy(() =>
  retry(() => import("./pages/PrivacyPolicyBoca")),
);

const InsiderDashBoardPage = lazy(() =>
  retry(() => import("./pages/loginusers/InsiderDashboard")),
);
const InternationalHotelBooking = lazy(() =>
  retry(() => import("./pages/flight/InternationalHotelBooking")),
);

const BookingHistoryDetail = lazy(() =>
  retry(() => import("./pages/loginusers/BookingHistoryDetail")),
);

const VerifyTicket = lazy(() => retry(() => import("./pages/verify_ticket")));

const FlymyaIconLoading = lazy(() =>
  retry(() => import("./components/FlymyaIconLoading")),
);

const VerifyEmail = lazy(() =>
  retry(() => import("./pages/login/VerifyEmail")),
);

const VerifyArrangerEmail = lazy(() =>
  retry(() => import("./pages/loginusers/CreatePasswordVerifyEmail")),
);

const EmailLinkOtp = lazy(() =>
  retry(() => import("./pages/login/EmailLinkAuth")),
);

const GorgiasChatIcon = lazy(() =>
  retry(() => import("./components/GorgiasChatIcon")),
);

const App = () => {
  const [open, setOpen] = useState(false);
  const [clientTag] = useState(getClientTag(window.location.search));
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const [themeColor, setThemeColor] = useState(theme);
  const [logo, setLogo] = useState(null);
  const [flag, setFlag] = useState(null);
  const [clientActive, setClientActive] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    const checkPath = path.split("/");
    const isInApp =
      checkPath.length > 2 &&
      (checkPath[2] === "in-app" || checkPath[2] === "in-web")
        ? true
        : false;
    const getLoginDetailFromLS = localStorage.getItem(LOGIN_PEOPLE_DETAIL);

    if (
      isAuthenticated() &&
      localStorage.getItem(IS_CUSTOMER) &&
      localStorage.getItem(IS_CUSTOMER) === "yes" &&
      (getLoginDetailFromLS === "false" ||
        getLoginDetailFromLS === "" ||
        getLoginDetailFromLS === "undefined") &&
      !isInApp &&
      checkPath.length > 2
    ) {
      removeFromLocalStorage();
      setOpen(true);
    }
  }, []);
  const getTheme = useCallback(() => {
    clientV3
      .query({
        query: GET_THEME_BY_CLIENT,
        fetchPolicy: "network-only",
        variables: {
          param: (isInApp || isInWeb) && clientTag ? clientTag : "default",
          orderBy: [{ column: "id", order: "DESC" }],
          first: 20,
          page: 1,
        },
      })
      .then((response) => {
        setLogo(
          response.data.client.attachments &&
            response.data.client.attachments.length > 0
            ? response.data.client.attachments[0].download_link
            : null,
        );
        localStorage.setItem("clientActive", response.data.client.active);
        setClientActive(response.data.client.active);
        response.data.client.active &&
          setThemeColor(
            createTheme({
              components: {
                MuiGrid: {
                  styleOverrides: {
                    root: {
                      "& .highlight": {
                        background: response.data.client.color1
                          ? response.data.client.color1
                          : "#6e51a2",
                        color: "#fff",
                      },
                      "& .detailBox:hover": {
                        border: response.data.client.color1
                          ? `1px solid${response.data.client.color1}`
                          : "1px solid #6e51a2",
                      },
                      "& .fullCircle,.arrivalPoint": {
                        backgroundColor: response.data.client.color1
                          ? response.data.client.color1
                          : "#6e51a2",
                        border: response.data.client.color1
                          ? `1px solid${response.data.client.color1}`
                          : "1px solid #6e51a2",
                      },
                      "& .circle,.departurePoint": {
                        border: response.data.client.color1
                          ? `1px solid${response.data.client.color1}`
                          : "1px solid #6e51a2",
                      },
                      "& .covidInfoContainer": {
                        border: response.data.client.color1
                          ? `1px solid${response.data.client.color1}`
                          : "1px solid #6e51a2",
                      },
                      "&.filterInfoContainer": {
                        border: response.data.client.color2
                          ? `1px solid${response.data.client.color2} !important`
                          : "1px solid #ffd41c !important",
                      },
                      "& .CalendarDay__selected,.selectedMultiDateBox": {
                        backgroundColor: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                      },
                      "& .DateInput_fangStroke": {
                        fill: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                        stroke: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                      },
                      "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
                        {
                          backgroundColor: response.data.client.color1
                            ? `${response.data.client.color1} !important`
                            : "#6e51a2 !important",
                        },
                      "& .airportSearchIcon,.nextDate svg,.previousDate svg": {
                        color: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                      },
                      "& .airportNames:hover": {
                        color: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                        cursor: "pointer",
                      },
                    },
                  },
                },
                MuiButton: {
                  styleOverrides: {
                    root: {
                      "&.Mui-disabled": {
                        background: response.data.client.color1
                          ? response.data.client.color1
                          : "#6e51a2",
                        color: "#fff",
                      },
                      "&.btnEditSearch": {
                        color: response.data.client.color2
                          ? response.data.client.color2
                          : "#ffd41c",

                        border: response.data.client.color2
                          ? `1px solid ${response.data.client.color2} !important`
                          : "1px solid #ffd41c !important",
                      },
                      "&.btnFilterSubmit": {
                        color: "#fff",
                        backgroundColor: response.data.client.color1
                          ? `${response.data.client.color2} !important`
                          : "#ffd41c !important",
                        border: response.data.client.color2
                          ? `1px solid ${response.data.client.color2} !important`
                          : "1px solid #ffd41c !important",
                      },
                      "&.btnFilter": {
                        color: "#231f20",
                        backgroundColor: "transparent",
                        border: response.data.client.color2
                          ? `1px solid ${response.data.client.color2} !important`
                          : "1px solid #ffd41c !important",
                      },
                      "&.btnFiltered": {
                        color: "#231f20",
                        backgroundColor: response.data.client.color1
                          ? `${response.data.client.color2} !important`
                          : "#ffd41c !important",
                        border: response.data.client.color2
                          ? `1px solid ${response.data.client.color2} !important`
                          : "1px solid #ffd41c !important",
                      },
                      "&.btnFilter:hover": {
                        color: "#231f20",
                        backgroundColor: response.data.client.color1
                          ? `${response.data.client.color2} !important`
                          : "#ffd41c !important",
                      },
                      "&.btnClearAll:hover": {
                        color: "#fff",
                      },
                      "&.choosePackBtn:hover": {
                        backgroundColor: response.data.client.color1
                          ? response.data.client.color1
                          : "#6e51a2",
                        color: "#fff",
                      },
                    },
                  },
                },
              },
              palette: {
                primary: {
                  main: response.data.client.color1
                    ? response.data.client.color1
                    : "#6e51a2",
                  bg:
                    clientTag !== "citizenspay" &&
                    clientTag !== "mpitesan" &&
                    clientTag !== "ayapay" &&
                    clientTag !== "momoney" &&
                    response.data.client.color1
                      ? response.data.client.color1
                      : clientTag === "mpitesan" ||
                        clientTag === "ayapay" ||
                        clientTag === "citizenspay" ||
                        clientTag === "momoney"
                      ? "#fff"
                      : "#6e51a2",
                  contrastText: "#fff",
                },
                info: {
                  main: response.data.client.color2
                    ? response.data.client.color2
                    : "#ffd41c",
                  contrastText: clientTag === "CR" || "" ? "#231f20" : "#fff",
                },
                secondary: {
                  main: response.data.client.color3
                    ? response.data.client.color3
                    : "#e6eaed",
                  contrastText: clientTag === "CR" || "" ? "#231f20" : "#fff",
                },
              },
            }),
          );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [clientTag]);
  useEffect(() => {
    getTheme();
    // !flag && getTheme();
    // setFlag(true);
  }, [flag, getTheme]);

  return (
    <ThemeProvider theme={themeColor ? themeColor : theme}>
      <BrowserRouter>
        <Suspense fallback={<></>}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={5000}
            onClose={() => setOpen(false)}
            TransitionComponent={Slide}
          >
            <Alert
              onClose={() => setOpen(false)}
              variant="filled"
              severity="warning"
            >
              The sign-up form must first be filled out. Even so, you may still
              buy as a guest.
            </Alert>
          </Snackbar>
          <GorgiasProvider>
            <Routes>
              {!clientActive && (
                <Route path="*" element={<Navigate to="/en" replace />} />
              )}
              <Route exact path="/:locale" element={<MainPage logo={logo} />} />

              {/** For the redirect url */}
              <Route
                exact
                path={`/:locale/redirect/:clientName`}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/flight"
                element={<MainPage logo={logo} />}
              />
              <Route exact path="/" element={<MainPage logo={logo} />} />

              {/** Verify Ticket */}
              <Route
                exact
                path="/:locale/verify-ticket"
                element={<VerifyTicket />}
              />
              <Route
                exact
                path="/:locale/in-app/verify-ticket"
                element={<VerifyTicket />}
              />
              <Route
                exact
                path="/:locale/in-web/verify-ticket"
                element={<VerifyTicket />}
              />

              {/** Verify Email */}
              <Route
                exact
                path="/:locale/email-verify"
                element={<VerifyEmail />}
              />

              {/** Redirect Verify Email */}
              <Route
                exact
                path="/:locale/just-email-verify"
                element={<VerifyArrangerEmail />}
              />

              {/** Redirect Email Link */}
              <Route
                exact
                path="/:locale/email-link-auth"
                element={<EmailLinkOtp />}
              />

              {/*Flight url*/}
              <Route
                exact
                path="/:locale/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID/:returnUUID"
                element={<FlightResult />}
              />
              <Route
                exact
                path="/:locale/flight/:flightType/customer-details/:flightUUID"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/flight/:flightType/customer-details/:flightUUID/:returnFlightUUID"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/flight/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              {/* Redirect to update password url */}
              <Route
                exact
                path="/:locale/forgot-password"
                element={<ForgetPassword />}
              />
              <Route
                exact
                path="/:locale/create-password"
                element={<CreatePassword />}
              />
              <Route
                exact
                path="/:locale/flight/payment-method/:bookingId/:returnBookingId"
                element={<PaymentPage />}
              />
              <Route
                exact
                path="/:locale/flight/confirmation/:bookingId"
                element={<ConfirmationPage />}
              />
              {/*Flight url*/}

              {/*Hotel url*/}
              <Route
                exact
                path="/:locale/hotel/"
                element={<MainPage logo={logo} />}
              />

              <Route
                exact
                path="/:locale/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
                element={<HotelSearchResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
                element={<HotelDetailPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
                element={<HotelCustomerPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/hotel/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/hotel/payment"
                element={<HotelPaymentPage />}
              />
              <Route
                exact
                path="/:locale/hotel/confirmation/:reservationId"
                element={<ConfirmationPage logo={logo} />}
              />
              {/*Hotel url*/}

              {/*Bus url*/}
              <Route
                exact
                path="/:locale/bus"
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
                element={<BusResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/select-seat/:busDetailId"
                element={<BusSeatList logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/contact-info/:busDetailId"
                element={<BusCustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/confirmation/:bookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/transaction-fail"
                element={<BusTransactionFail />}
              />
              {/*Bus url*/}

              {/* car rental url*/}
              <Route
                exact
                path="/:locale/car-rental"
                element={<CarHome logo={logo} />}
              />
              {/* car rental url*/}

              {/* balloon url*/}
              <Route
                exact
                path="/:locale/balloon"
                element={<BalloonHome logo={logo} />}
              />
              {/* balloon url*/}

              {/* travel services url*/}
              <Route
                exact
                path="/:locale/travel-services/:type"
                element={<TravelServicesHome logo={logo} />}
              />
              {/* travel services url*/}

              <Route
                exact
                path="/:locale/in-app/promotion"
                element={<PromotionsPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/promotion/:title"
                element={<PromotionDetailPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/promotion"
                element={<PromotionsPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/promotion/:title"
                element={<PromotionDetailPage logo={logo} />}
              />
              <Route exact path="/:locale/contact-us" element={<ContactUs />} />
              <Route
                exact
                path="/:locale/in-app/contact-us"
                element={<ContactUs logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/contact-us"
                element={<ContactUs logo={logo} />}
              />
              <Route exact path="/:locale/about-us" element={<AboutUs />} />
              <Route
                exact
                path="/:locale/in-app/about-us"
                element={<AboutUs logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/about-us"
                element={<AboutUs logo={logo} />}
              />
              <Route
                exact
                path="/:locale/privacy-policy"
                element={<PrivacyPolicy logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/privacy-policy"
                element={<PrivacyPolicy logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/privacy-policy"
                element={<PrivacyPolicy logo={logo} />}
              />
              <Route
                exact
                path="/:locale/privacy-policy-boca"
                element={<PrivacyPolicyBoca />}
              />
              <Route element={<Page404 />} />

              <Route
                exact
                path="/:locale/balance/:b2buserId"
                element={<B2BBalancePage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/address-book/:userId"
                element={<AddressBook logo={logo} />}
              />
              <Route
                path="/:locale/in-app/address-book/:userId"
                exact
                element={<AddressBook logo={logo} />}
              />
              <Route
                path="/:locale/in-web/address-book/:userId"
                exact
                element={<AddressBook logo={logo} />}
              />
              <Route
                exact
                path="/:locale/address-book-detail/:userId"
                element={<AddressBookDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/address-book-detail/:userId"
                element={<AddressBookDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/address-book-detail/:userId"
                element={<AddressBookDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/booking-history/:b2buserId"
                element={<BookingHistory logo={logo} />}
              />
              <Route
                exact
                path="/:locale/booking-history/:b2buserId/booking-detail/:resId"
                element={<BookingHistoryDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/booking-history/:b2buserId"
                element={<BookingHistory logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/booking-history/:b2buserId/booking-detail/:resId"
                element={<BookingHistoryDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/booking-history/:b2buserId/booking-detail/:resId"
                element={<BookingHistoryDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/booking-history/:b2buserId"
                element={<BookingHistory logo={logo} />}
              />

              <Route
                path="/:locale/profile/:userId"
                exact
                element={<Profile logo={logo} />}
              />
              <Route
                path="/:locale/in-app/profile/:userId"
                exact
                element={<Profile logo={logo} />}
              />
              <Route
                path="/:locale/in-web/profile/:userId"
                exact
                element={<Profile logo={logo} />}
              />
              <Route
                exact
                path="/:locale/insider-dashboard/:b2cuserPeopleId"
                element={<InsiderDashBoardPage logo={logo} />}
              />
              <Route
                path="/:locale/in-web/insider-dashboard/:b2cuserPeopleId"
                exact
                element={<InsiderDashBoardPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/b2b-topup/:b2buserId/:currency/:type"
                element={<B2BTopup logo={logo} />}
              />
              <Route
                exact
                path="/:locale/b2b-transfer/:b2buserId/:currency/:type"
                element={<B2BTransfer logo={logo} />}
              />

              {/* in-app flight url */}
              <Route exact path="/in-app" element={<MainPage logo={logo} />} />
              <Route
                exact
                path="/:locale/in-app"
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight"
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID/:returnUUID"
                element={<FlightResult />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/customer-details/:flightUUID/"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/customer-details/:flightUUID/:returnFlightUUID"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/confirmation/:bookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/confirmation/:bookingId/:returnBookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              {/* in-app flight url */}

              {/* in-app bus url */}
              <Route
                exact
                path="/:locale/in-app/bus"
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
                element={<BusResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/select-seat/:busDetailId"
                element={<BusSeatList logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/contact-info/:busDetailId"
                element={<BusCustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/confirmation/:bookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/transaction-fail"
                element={<BusTransactionFail />}
              />
              {/* in-app bus url */}

              {/* in-app hotel url */}
              <Route
                exact
                path="/:locale/in-app/hotel"
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
                element={<HotelSearchResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
                element={<HotelDetailPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
                element={<HotelCustomerPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel/payment"
                element={<HotelPaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel/confirmation/:reservationId"
                element={<ConfirmationPage logo={logo} />}
              />
              {/* in-app hotel url */}

              {/* car rental url*/}
              <Route
                exact
                path="/:locale/in-app/car-rental"
                element={<CarHome logo={logo} />}
              />
              {/* car rental url*/}

              {/* in-app balloon url*/}
              <Route
                exact
                path="/:locale/in-app/balloon"
                element={<BalloonHome logo={logo} />}
              />
              {/* in-app balloon url*/}

              {/* in-app travel services url*/}
              <Route
                exact
                path="/:locale/in-app/travel-services/:type"
                element={<TravelServicesHome logo={logo} />}
              />
              {/* in-app travel services url*/}

              {/* in-app promotion */}
              <Route
                exact
                path="/:locale/in-app/promotion"
                component={PromotionsPage}
              />
              <Route
                exact
                path="/:locale/in-app/promotion/:title"
                component={PromotionDetailPage}
              />
              {/* in-app promotion */}

              {/* kbzpay inweb url*/}
              {localStorage.getItem("clientActive") && (
                <Route
                  exact
                  path="/:locale/in-web"
                  element={<MainPage logo={logo} active={clientActive} />}
                />
              )}

              {/* in-web flight url */}
              <Route
                exact
                path="/in-web"
                element={
                  <MainPage
                    logo={logo}
                    active={localStorage.getItem("clientActive")}
                  />
                }
              />
              <Route
                exact
                path="/:locale/in-web/flight"
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID/:returnUUID"
                element={<FlightResult />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/customer-details/:flightUUID/"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/customer-details/:flightUUID/:returnFlightUUID"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/payment-method/:bookingId/:returnBookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/confirmation/:bookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/confirmation/:bookingId/:returnBookingId"
                element={<ConfirmationPage logo={logo} />}
              />

              {/* in-web bus url */}
              <Route
                exact
                path="/:locale/in-web/bus"
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
                element={<BusResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/select-seat/:busDetailId"
                element={<BusSeatList logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/contact-info/:busDetailId"
                element={<BusCustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/confirmation/:bookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/transaction-fail"
                element={<BusTransactionFail logo={logo} />}
              />
              {/* in-web bus url*/}

              {/* in-web carrental url*/}
              <Route
                exact
                path="/:locale/in-web/car-rental"
                element={<CarHome logo={logo} />}
              />
              {/* in-web carrental url*/}

              {/* in-web balloon url*/}
              <Route
                exact
                path="/:locale/in-web/balloon"
                element={<BalloonHome logo={logo} />}
              />
              {/* in-web balloon url*/}

              {/* in-web travel services url*/}
              <Route
                exact
                path="/:locale/in-web/travel-services/:type"
                element={<TravelServicesHome logo={logo} />}
              />
              {/* in-web travel services url*/}

              {/* in-web hotel url */}
              <Route
                exact
                path="/:locale/in-web/hotel"
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
                element={<HotelSearchResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
                element={<HotelDetailPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
                element={<HotelCustomerPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/hotel/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/hotel/confirmation/:reservationId"
                element={<ConfirmationPage logo={logo} />}
              />
              {/* in-web hotel url */}
              <Route
                exact
                path="/:locale/in-web/promotion"
                element={<PromotionsPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/promotion/:title"
                element={<PromotionDetailPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/show-message"
                element={<ShowResultMessage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/show-message"
                element={<ShowResultMessage />}
              />
              <Route
                exact
                path="/redirect-intl-hotel"
                element={<InternationalHotelBooking />}
              />
            </Routes>
            <GorgiasChatIcon />
          </GorgiasProvider>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
