export const DivisionList = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
];

export const CardList = ["N", "F", "P", "T", "E"];
export const OneList = [
  "HsaBaTa",
  "HsaDaNa",
  "RaBaYa",
  "KaPaTa",
  "PaWaNa",
  "KaMaTa",
  "MaLaNa",
  "AhGaYa",
  "BaMaNa",
  "DaPhaYa",
  "HaPaNa",
  "KaMaNa",
  "KhaLaPha",
  "KhaPhaNa",
  "LaGaNa",
  "MaKaNa",
  "MaKaTa",
  "MaKhaBa",
  "MaMaNa",
  "NaMaNa",
  "MaNyaNa",
  "MaSaNa",
  "PaNaDa",
  "PaTaAh",
  "SaLaNa",
  "SaPaBa",
  "TaNaNa",
  "YaKaNa",
  "WaMaNa",
];
export const TwoList = [
  "BaLaKha",
  "DaMaSa",
  "LaKaNa",
  "MaSaNa",
  "PhaSaNa",
  "PhaYaSa",
  "YaTaNa",
  "YaThaNa",
];
export const ThreeList = [
  "RaRaTha",
  "LaThaNa",
  "BaGaLa",
  "KaMaMa",
  "SaKaLa",
  "WaLaMa",
  "BaAhNa",
  "BaThaSa",
  "KaSaKa",
  "KaDaNa",
  "KaKaYa",
  "LaBaNa",
  "MaWaTa",
  "PaKaNa",
  "PhaPaNa",
  "ThaTaKa",
  "ThaTaNa",
];
export const FourList = [
  "HsaMaNa",
  "HaKhaNa",
  "HtaTaLa",
  "KaKhaNa",
  "KaPaLa",
  "MaTaNa",
  "MaTaPa",
  "PaLaWa",
  "PhaLaNa",
  "TaTaNa",
  "TaZaNa",
  "YaKhaDa",
  "YaZaNa",
];
export const FiveList = [
  "KhaPaNa",
  "DaHaNa",
  "MaPaLa",
  "HtaPaKha",
  "HsaMaRa",
  "AhTaNa",
  "AhYaTa",
  "BaMaNa",
  "BaTaLa",
  "DaPaYa",
  "HaMaLa",
  "HtaKhaNa",
  "KaBaLa",
  "KaLaHta",
  "KaLaNa",
  "KaLaTa",
  "KaLaWa",
  "KaNaNa",
  "KaMaNa",
  "KhaTaNa",
  "KaThaNa",
  "KhaOoNa",
  "KhaOoTa",
  "LaHaNa",
  "LaYaNa",
  "KanaNa",
  "MaLaNa",
  "MaMaTa",
  "MaThaNa",
  "NaYaNa",
  "NgaZaNa",
  "PaLaNa",
  "PaSaNa",
  "SaKaNa",
  "SaLaKa",
  "TaSaNa",
  "TaNaNa",
  "WaLaNa",
  "YaBaNa",
  "YaMaPa",
  "MaMaNa",
  "MaYaNa",
  "PaLaBa",
  "PhaPaNa",
  "TaMaNa",
  "WaThaNa",
  "YaONa",
];

export const SixList = [
  "KaLaA",
  "PaLaTa",
  "PaKaMa",
  "BaPaNa",
  "HtaWaNa",
  "KaThaNa",
  "KaYaYa",
  "LaLaNa",
  "MaAhNa",
  "MaAhYa",
  "PaLaNa",
  "TaThaYa",
  "YaPhaNa",
  "MaMaNa",
  "KaSaNa",
  "KhaMaKa",
  "MaTaNa",
  "ThaYaKha",
];
export const SevenList = [
  "AhPhaNa",
  "DaOoNa",
  "HtaTaPa",
  "KaPaKa",
  "KaTaKha",
  "LaPaTa",
  "MaLaNa",
  "NaTaLa",
  "NyaLaPa",
  "PaKhaNa",
  "PaMaNa",
  "PaTaNa",
  "PhaMaNa",
  "TaNgaNa",
  "ThaNaPa",
  "ThaWaTa",
  "YaKaNa",
  "YaTaNa",
  "ZaKaNa",
  "AhTaNa",
  "KaKaNa",
  "KaWaNa",
  "MaNyaNa",
  "PaKhaTa",
  "PaTaTa",
  "ThaKaNa",
  "WaMaNa",
  "YaTaYa",
];
export const EightList = [
  "MaHtaNa",
  "AhLaNa",
  "HtaLaNa",
  "KaHtaNa",
  "KhaMaNa",
  "MaBaNa",
  "MaLaNa",
  "MaMaNa",
  "MaTaNa",
  "NaMaNa",
  "NgaPhaNa",
  "PaMaNa",
  "PaPhaNa",
  "SaMaNa",
  "SoPaWa",
  "SaTaYa",
  "TaTaKa",
  "YaNaKha",
  "YaSaKa",
  "GaGaNa",
  "KaMaNa",
  "MaKaNa",
  "MaThaNa",
  "PaKhaKa",
  "SaLaNa",
  "SaPhaNa",
  "ThaYaNa",
];
export const NineList = [
  "NgaZaNa",
  "AhMaZa",
  "KaPaTa",
  "KaSaNa",
  "KhaMaSa",
  "LaWaNa",
  "MaHtaLa",
  "MaKaNa",
  "MaLaNa",
  "MaMaNa",
  "MaNaTa",
  "MaTaYa",
  "MaYaMa",
  "MaYaTa",
  "NgaThaYa",
  "NyaONa",
  "PaBaNa",
  "PaMaNa",
  "PaOLa",
  "SaKaNa",
  "SaKaTa",
  "TaKaTa",
  "TaTaOo",
  "ThaPaKa",
  "ThaSaNa",
  "YaMaTha",
  "OoTaTha",
  "ZaYaTha",
  "PaBaTha",
  "DaKhaTha",
  "ZaBaTha",
  "AhMaYa",
  "KhaAhZa",
  "MaHaMa",
  "MaKhaNa",
  "MaNaMa",
  "MaThaNa",
  "NaHtaKa",
  "PaKaKha",
  "PaThaKa",
  "TaKaNa",
  "TaThaNa",
  "WaTaNa",
];
export const TenList = [
  "KhaZaNa",
  "BaLaNa",
  "KaMaYa",
  "KhaSaNa",
  "LaMaNa",
  "MaLaMa",
  "PaMaNa",
  "ThaPhaYa",
  "YaMaNa",
  "KaHtaNa",
  "MaDaMa",
  "MaDaNa",
  "ThaHtaNa",
];
export const ElevenList = [
  "TaPaWa",
  "BaThaTa",
  "GaMana",
  "KaPhaNa",
  "KaTaNa",
  "MaAhNa",
  "MaAhTa",
  "MaPaTa",
  "MaONa",
  "MaPaNa",
  "MaTaNa",
  "PaNaKa",
  "PaTaNa",
  "SaTaNa",
  "TaKaNa",
  "ThaTaNa",
  "YaBaNa",
  "YaThaTa",
  "AhMaNa",
  "KaLaTa",
];
export const TwelveList = [
  "AhLaNa",
  "AhSaNa",
  "BaHaNa",
  "BaTaHta",
  "DaGama",
  "DaGaNa",
  "DaGaSa",
  "DaGaTa",
  "DaGaYa",
  "DaLaNa",
  "DaPaNa",
  "HtaTaPa",
  "KaKaKa",
  "KaKhaKa",
  "KaMaNa",
  "KaMaTa",
  "KaMaYa",
  "KaTaNa",
  "KaTaTa",
  "KhaYaNa",
  "LaKaNa",
  "LaMaNa",
  "LaMaTa",
  "LaThaNa",
  "LaThaYa",
  "MaBaNa",
  "MaGaDa",
  "MaGaTa",
  "MaYaKa",
  "OKaMa",
  "OKaTa",
  "PaBaTa",
  "PaZaTa",
  "SaKaKha",
  "SaKaNa",
  "SaKhaNa",
  "TaKaNa",
  "TaMaNa",
  "TaTaNa",
  "TaTaTa",
  "ThaGaKa",
  "ThaKaTa",
  "ThaKhaNa",
  "ThaLaNa",
  "YaKaNa",
  "YaPaTha",
];
export const ThirteenList = [
  "KaTaLa",
  "NaTaRa",
  "KhaLaNa",
  "KaLaDa",
  "PaHsaNa",
  "MaKaHta",
  "PaHsaTa",
  "MaLaTa",
  "PaLaHta",
  "MaYaNa",
  "MaHsaNa",
  "MaHtaNa",
  "AhTaNa",
  "HaMaNa",
  "HaPaNa",
  "HaPaTa",
  "KaHaNa",
  "KaKaNa",
  "KaKhaNa",
  "KaLaHta",
  "KaLaNa",
  "KaLaTa",
  "KaMaNa",
  "KaTaKa",
  "KaTaNa",
  "KaTaTa",
  "KaThaNa",
  "KhaYaHa",
  "LaKaNa",
  "LaKhaTa",
  "LaLaNa",
  "LaYaNa",
  "MaBaNa",
  "MaHaYa",
  "MaKaNa",
  "MaKaTa",
  "MaKhaNa",
  "MaKhaTa",
  "MaLaNa",
  "MaMaHta",
  "MaMaNa",
  "MaMaTa",
  "MaNaNa",
  "MaNaTa",
  "MaNgaNa",
  "MaPaHta",
  "MaPaNa",
  "MaPaTa",
  "MaPhaNa",
  "MaPhaTa",
  "MaSaNa",
  "MaSaTa",
  "MaHtaTa",
  "MaTaNa",
  "MaTaTa",
  "MaYaHta",
  "MaRaTa",
  "MaRaNa",
  "MaYaTa",
  "NaKhaNa",
  "NaKhaTa",
  "NaMaTa",
  "NaPhaNa",
  "NaHsaNa",
  "NaSaNa",
  "NaTaNa",
  "NyaYaNa",
  "PhaKhaNa",
  "PaLaNa",
  "PaLaTa",
  "PaPaKa",
  "PaTaYa",
  "PaWaNa",
  "PaYaNa",
  "SaSaNa",
  "TaKaNa",
  "TaKhaLa",
  "TaLaNa",
  "TaMaNya",
  "TaTaNa",
  "TaYaNa",
  "ThaNaNa",
  "ThaPaNa",
  "YaNgaNa",
  "YaSaNa",
];
export const FourteenList = [
  "KaKaHTa",
  "AhGapa",
  "AhMaNh",
  "AhMaTa",
  "BaKaLa",
  "DaDaYa",
  "DaNaPha",
  "HaKaKa",
  "HaThaTa",
  "KaKaTa",
  "KaKaNa",
  "KaKhaNa",
  "KaLaNa",
  "KaPaNa",
  "LaMaNa",
  "LaPaTa",
  "MaAhNa",
  "MaAhPa",
  "MaMaKa",
  "MaMaNa",
  "NgaPaTa",
  "NgaSaNa",
  "NgaThaKha",
  "NgaYaKa",
  "NyaTaNa",
  "PaSaLa",
  "PaTaNa",
  "PaThaNa",
  "PaThaYa",
  "PhaPaNa",
  "ThaPaNa",
  "WaKhaMa",
  "YaKaNa",
  "YaThaYa",
  "ZaLaNa",
];
