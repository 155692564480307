import { gql } from "apollo-boost";

const GET_PROMOTION = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    promotion(orderBy: $orderBy, first: $first, page: $page, filter: $filter) {
      data {
        id
        slug
        search_url
        web_url
        order
        customer_classes
        clients {
          id
          name
          order
        }
        name
        name_en
        slug
        description
        description_en
        call_to_action
        show_homepage_popup
        manual_campaign {
          id
          name
        }
        promotion_checkout_type {
          id
          name
        }
        employee {
          id
          email
        }
        attachments {
          id
          filename
          download_link
        }
        client_promotion {
          order
        }
      }

      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_PROMOTIONBANNER = gql`
  mutation createPromotion($input: promotionInput) {
    createPromotion(input: $input) {
      id
      search_url
      manual_campaign {
        id
        name
      }
      promotion_checkout_type {
        id
        name
      }
      employee {
        id
        email
      }
      attachments {
        id
        filename
        download_link
      }
    }
  }
`;

const GET_CHECKOUT_TYPE = gql`
  query promotionCheckoutType {
    promotionCheckoutType {
      id
      name
      promotion {
        id
        search_url
        manual_campaign {
          id
          name
        }
        promotion_checkout_type {
          id
          name
        }
        employee {
          id
          email
        }
        attachments {
          id
          filename
          download_link
        }
      }
    }
  }
`;

const GET_PROMOTION_BY_CLIENT = gql`
  query ($input: clientPromotionsInput) {
    clientPromotions(input: $input) {
      id
      slug
      name
      name_en
      description
      description_en
      search_url
      customer_classes
      call_to_action
      web_url
      attachments {
        id
        filename
        download_link
      }
      clients {
        id
        name
        color1
        color2
        color3
      }
      client_promotion {
        order
        homepage
        popup
        active
      }
    }
  }
`;
const GET_THEME_BY_CLIENT = gql`
  query ($param: String!) {
    client(param: $param) {
      id
      attachments {
        id
        filename
        download_link
      }
      active
      color1
      color2
      color3
    }
  }
`;

export {
  GET_PROMOTION,
  CREATE_PROMOTIONBANNER,
  GET_CHECKOUT_TYPE,
  GET_PROMOTION_BY_CLIENT,
  GET_THEME_BY_CLIENT,
};
