import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Popover } from "@material-ui/core";
import {
  LOGIN_USER_ID,
  IDENTIFIER,
  LOGIN_USER_PEOPLE,
  IS_CUSTOMER,
  IMG_CDN_URL,
} from "../config/constants";
import {
  MPITESAN,
  CITIZENPAY,
  MOMONEY,
  AYAPAY,
  KBZ,
  WAVEMONEY,
} from "../config/inappclients";
import { isAuthenticated } from "../lib/auth";
import { clientV3 } from "../utils/v3utils";
import { LOGOUT_QUERY } from "../queries/loginquery";
import { checkInApp, checkInWeb, getClientTag } from "../utils/checkurl";
import OTP_LOGIN_EMAIL_REGEX from "../config/regex/otpLoginEmailRegex";
import { removeFromLocalStorage } from "../utils/logoututil";
import { useTheme } from "@mui/material";
import MediaQuery from "react-responsive";
const LoginIconWhite = `${IMG_CDN_URL}/header-menu/login_icon_white.svg`;
const LoginIconBlack = `${IMG_CDN_URL}/header-menu/login_icon_black.svg`;
const LoginIconGreen = `${IMG_CDN_URL}/header-menu/login_green.svg`;

const AgentLoginMenu = ({
  selectedLanguage,
  isShow,
  setIsShow,
  isMenuShow,
  setIsMenuShow,
  isMobile,
  isWhiteColor,
  isInApp,
  userData,
  pageContent,
  showModal,
  pageName,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientTag] = useState(getClientTag(window.location.search));
  const [displayUserIdentity, setDisplayUserIdentity] = useState("");

  const isCustomer =
    localStorage.getItem(IS_CUSTOMER) &&
    localStorage.getItem(IS_CUSTOMER) === "yes"
      ? true
      : false;

  const navigate = useNavigate();
  const location = useLocation();

  const getDisplayLoginUserIdentity = () => {
    const identifier = localStorage.getItem(IDENTIFIER);
    if (OTP_LOGIN_EMAIL_REGEX.test(identifier)) {
      setDisplayUserIdentity(identifier.split("@")[0]);
      return identifier.split("@")[0];
    }
    return identifier;
  };

  /** useEffect to load display name to state from localstorage when component is first mounted */
  useEffect(() => {
    setDisplayUserIdentity(getDisplayLoginUserIdentity());
  }, []);

  const commonFunc = () => {
    removeFromLocalStorage();
    if (checkInApp(location.pathname)) {
      window.location.href = `/${selectedLanguage}/in-app${window.location.search}`;
    } else if (checkInWeb(location.pathname)) {
      window.location.href = `/${selectedLanguage}/in-web${window.location.search}`;
    } else {
      window.location.href = `/${selectedLanguage}${window.location.search}`;
    }
  };

  const getAccountLogo = (isMobile, isInApp, isWhiteColor, clientTag) => {
    if (!isMobile) return LoginIconBlack;
    if (!isInApp) return LoginIconGreen;
    if (
      isWhiteColor &&
      (clientTag === MPITESAN ||
        clientTag === CITIZENPAY ||
        clientTag === MOMONEY ||
        clientTag === AYAPAY)
    )
      return LoginIconBlack;

    return LoginIconWhite;
  };

  const handleLogout = () => {
    clientV3
      .mutate({
        mutation: LOGOUT_QUERY,
      })
      .then(() => {
        commonFunc();
      })
      .catch((err) => {
        console.log(err);
        let msg =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors.length > 0 &&
          err.graphQLErrors[0]
            ? err.graphQLErrors[0].extensions.reason
            : "";
        if (msg === "Not Authenticated") {
          commonFunc();
        }
      });
  };

  return (
    <>
      {!isAuthenticated() && (
        <span
          className="login_btn"
          id="login_btn"
          onClick={() => {
            setIsShow(!isShow);
            //setIsMenuShow(!isShow);
          }}
        >
          <MediaQuery maxWidth={780}>
            <img
              src={
                isMobile &&
                (!isWhiteColor ||
                  (isWhiteColor && clientTag === CITIZENPAY) ||
                  (isWhiteColor && clientTag === MPITESAN) ||
                  (isWhiteColor && clientTag === AYAPAY) ||
                  (isWhiteColor && clientTag === MOMONEY))
                  ? LoginIconBlack
                  : isWhiteColor &&
                    (clientTag === KBZ || clientTag === WAVEMONEY)
                  ? LoginIconWhite
                  : LoginIconBlack
              }
              alt=""
              style={
                isMobile && pageName === "FlightConfirmation"
                  ? {
                      // position: "absolute",
                      width: "27px",
                      top: "13px",
                    }
                  : {
                      // position: "absolute",
                      right: pageName === undefined ? "3px" : "11px",
                      width: "25px",
                      top: "13px",
                      //bottom: "11px",
                    }
              }
              className={isMobile ? "mobile_login_icon" : "desktop_login_icon"}
            />
          </MediaQuery>
          <MediaQuery minWidth={780}>
            <img
              src={
                isMobile &&
                (!isWhiteColor ||
                  (isWhiteColor && clientTag === CITIZENPAY) ||
                  (isWhiteColor && clientTag === MPITESAN) ||
                  (isWhiteColor && clientTag === AYAPAY) ||
                  (isWhiteColor && clientTag === MOMONEY))
                  ? LoginIconBlack
                  : isWhiteColor &&
                    (clientTag === KBZ || clientTag === WAVEMONEY)
                  ? LoginIconWhite
                  : LoginIconBlack
              }
              alt=""
              className={isMobile ? "mobile_login_icon" : "desktop_login_icon"}
            />
          </MediaQuery>

          {!isMobile && <span className="login_title">Login</span>}
        </span>
      )}
      {isAuthenticated() && (
        <>
          {!isMobile && (
            <span
              aria-describedby="logout"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <img
                src={LoginIconGreen}
                alt=""
                className={"desktop_loggedin_icon"}
              />
            </span>
          )}
          {isMobile && (
            <span
              className={`login_btn ${!isInApp ? "not_inapp_login" : ""}`}
              id="login_btn"
              aria-describedby="logout"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <img
                src={getAccountLogo(isMobile, isInApp, isWhiteColor, clientTag)}
                alt=""
                className={`${isMobile ? "mobile_login_icon" : ""} ${
                  !isInApp ? "not_inapp_icon" : ""
                }`}
                width="30"
              />
            </span>
          )}
          <Popover
            id="logout"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <p className="emailAddressLoginPopoverText">
              <span className="signedTitle">
                {pageContent && pageContent.signedInAs
                  ? pageContent.signedInAs
                  : "Signed in as"}
              </span>
              <span className="signedName">
                {displayUserIdentity?.length > 21
                  ? `${displayUserIdentity.substring(0, 21)}...`
                  : displayUserIdentity}
              </span>
            </p>
            <hr className="hr_menu"></hr>
            {!isCustomer && (
              <span
                onClick={() =>
                  navigate({
                    pathname: `/${selectedLanguage}/balance/${localStorage.getItem(
                      LOGIN_USER_ID,
                    )}`,
                    search: window.location.search,
                  })
                }
              >
                {pageContent && pageContent.balance
                  ? pageContent.balance
                  : "Balance"}
              </span>
            )}
            {isCustomer && (
              <>
                <span
                  onClick={() =>
                    navigate({
                      pathname: `/${selectedLanguage}/${
                        checkInApp(location.pathname)
                          ? "in-app/"
                          : checkInWeb(location.pathname)
                          ? "in-web/"
                          : ""
                      }profile/${localStorage.getItem(LOGIN_USER_ID)}`,
                      search: window.location.search,
                      userData,
                    })
                  }
                  style={{ position: "relative" }}
                >
                  {pageContent && pageContent.viewProfile
                    ? pageContent.viewProfile
                    : "View Profile"}{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      color: theme.palette.info.contrastText,
                      fontWeight: "bold",
                      letterSpacing: "0.5px",
                      background: theme.palette.info.main,
                      padding: "2px 5px",
                      borderRadius: "10px",
                      width: "25px",
                      position: "absolute",
                      top: "-5px",
                      marginLeft: "7px",
                    }}
                  >
                    New!
                  </span>
                </span>
                <span
                  onClick={() =>
                    navigate({
                      pathname: `/${selectedLanguage}/${
                        checkInApp(location.pathname)
                          ? "in-app/"
                          : checkInWeb(location.pathname)
                          ? "in-web/"
                          : ""
                      }address-book/${localStorage.getItem(LOGIN_USER_ID)}`,
                      search: window.location.search,
                    })
                  }
                  style={{ position: "relative" }}
                >
                  {pageContent && pageContent.addressBook
                    ? pageContent.addressBook
                    : "Address Book"}{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      color: theme.palette.info.contrastText,
                      fontWeight: "bold",
                      letterSpacing: "0.5px",
                      background: theme.palette.info.main,
                      padding: "2px 5px",
                      borderRadius: "10px",
                      width: "25px",
                      position: "absolute",
                      top: "-5px",
                      marginLeft: "7px",
                    }}
                  >
                    New!
                  </span>
                </span>
              </>
            )}
            <span
              onClick={() =>
                navigate({
                  pathname: `/${selectedLanguage}/${
                    checkInApp(location.pathname)
                      ? "in-app/"
                      : checkInWeb(location.pathname)
                      ? "in-web/"
                      : ""
                  }booking-history/${localStorage.getItem(LOGIN_USER_ID)}`,
                  search: window.location.search,
                })
              }
            >
              {pageContent && pageContent.bookingHistory
                ? pageContent.bookingHistory
                : "Booking History"}
            </span>
            {isCustomer && (
              <span
                onClick={() =>
                  navigate({
                    pathname: `/${selectedLanguage}/${
                      checkInApp(location.pathname)
                        ? "in-app/"
                        : checkInWeb(location.pathname)
                        ? "in-web/"
                        : ""
                    }insider-dashboard/${localStorage.getItem(
                      LOGIN_USER_PEOPLE,
                    )}`,
                    search: window.location.search,
                  })
                }
              >
                {pageContent && pageContent.insiderDashboard
                  ? pageContent.insiderDashboard
                  : "Insider Dashboard"}
              </span>
            )}
            <hr className="hr_menu"></hr>
            <span onClick={handleLogout}>
              {pageContent && pageContent.logOut
                ? pageContent.logOut
                : "Logout"}
            </span>
          </Popover>
        </>
      )}
    </>
  );
};

export default AgentLoginMenu;
